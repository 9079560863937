import * as S from './footer.style';
import { Link } from 'react-router-dom';
import { LightSvg } from '@app/assets/images/svgs/light.svg';
import validator from 'validator';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsLetterSvg } from '@app/assets/images/svgs/news-letter.svg';
import { ReactComponent as LogoSvg } from '@app/assets/images/svgs/logo-1.svg';
import { TellSVg } from '@app/assets/images/svgs/tell.svg';
import { EmailSvg } from '@app/assets/images/svgs/email.svg';
import { FacebookSsg } from '@app/assets/images/svgs/facebook.ssg';
import { InstagramSvg } from '@app/assets/images/svgs/instagram.svg';
import { JoinNewsLetter, JoinSeller } from '@app/api/home.api';
import { toast } from 'react-toastify';

const FooterLayout: React.FC = () => {
  const { t } = useTranslation();
  const [seller, setSeller] = useState({ email: '' });
  const [newsLetter, setNewsLetter] = useState({ email: '' });
  const [sellerError, setSellerError] = useState('');
  const [newsLetterError, setNewsLetterError] = useState('');

  const joinSeller = async () => {
    if (validator.isEmpty(seller.email)) {
      setSellerError(t('required-email-address'));
    } else if (!validator.isEmail(seller.email)) {
      setSellerError(t('invalid-email-address'));
    } else {
      const response = await JoinSeller(seller.email);
      if (response.success) {
        toast.success(t('joined-seller'));
        setSeller({ email: '' });
        setSellerError('');
      } else {
        toast.error(t('join-failed'));
      }
    }
  };

  const joinNewsLetter = async () => {
    if (validator.isEmpty(newsLetter.email)) {
      setNewsLetterError(t('required-email-address'));
    } else if (!validator.isEmail(newsLetter.email)) {
      setNewsLetterError(t('invalid-email-address'));
    } else {
      const response = await JoinNewsLetter(newsLetter.email);
      if (response.success) {
        toast.success(t('joined-newsletter'));
        setNewsLetter({email: ""});
        setNewsLetterError("");
      } else {
        toast.error(t('join-failed'));
      }
    }
  };

  const checkNewsLetter = (email: string) => {
    setNewsLetter({ email });
    if (validator.isEmpty(email)) {
      setNewsLetterError(t('required-email-address'));
    } else if (!validator.isEmail(email)) {
      setNewsLetterError(t('invalid-email-address'));
    } else {
      setNewsLetterError('');
    }
  };

  const checkSeller = (email: string) => {
    setSeller({ email });
    if (validator.isEmpty(email)) {
      setSellerError(t('required-email-address'));
    } else if (!validator.isEmail(email)) {
      setSellerError(t('invalid-email-address'));
    } else {
      setSellerError('');
    }
  };

  return (
    <S.FooterLayout>
      <S.NeedHelp>
        <div className={'help'}>
          <div className={'title'}>
            <div className={'green-line'} />
            <span>{t('need-help')}</span>
            <div className={'green-line radius-left'} />
          </div>
          <div className={'body'}>
            <Link to={'/contact-us'} className={'box'}>
              <h3>
                <b>
                  <LightSvg />
                </b>
                {t('contact-sales-support')}
              </h3>
              <p>{t('contact-sales-support-description')}</p>
            </Link>
            <Link to={'/'} className={'box'}>
              <h3>
                <b>
                  <LightSvg />
                </b>
                {t('start-here')}
              </h3>
              <p>{t('start-here-description')}</p>
            </Link>
            <Link to={'/how-to-buy'} className={'box'}>
              <h3>
                <b>
                  <LightSvg />
                </b>
                {t('how-to-buy')}
              </h3>
              <p>{t('how-to-buy-description')}</p>
            </Link>
            <Link to={'/how-to-return'} className={'box'}>
              <h3>
                <b>
                  <LightSvg />
                </b>
                {t('how-to-return')}
              </h3>
              <p>{t('how-to-return-description')}</p>
            </Link>
          </div>
        </div>
        <div className={'newsletter'}>
          <b>
            <NewsLetterSvg />
          </b>
          <div className={'background'}>
            <div className="title">
              <p>{t('subscribe-title')}</p>
              <p>{t('subscribe-description')}</p>
            </div>

            <div className="email-form">
              <input
                type={'email'}
                value={newsLetter.email}
                onChange={(e) => checkNewsLetter(e.target.value)}
                placeholder={t('subscribe-placeholder')}
                aria-invalid={newsLetterError ? true : false}
              />
              {newsLetterError && <span>{newsLetterError}</span>}
              <button onClick={joinNewsLetter} type={'button'}>
                {t('subscribe')}
              </button>
            </div>
          </div>
        </div>
      </S.NeedHelp>

      <S.Footer>
        <Link className="logo-container" to={'/'}>
          <LogoSvg className={'logo'} />
        </Link>
        <div className={'row'}>
          <div className={'col'}>
            <ul>
              <li>
                <a>
                  <b>
                    <TellSVg />
                  </b>
                  +49 174 487 75 66
                </a>
              </li>
              <li>
                <a>
                  <b>
                    <EmailSvg />
                  </b>
                  Support@greenia.com
                </a>
              </li>
              <li className={'social'}>
                <p>{t('follow-us')}</p>
                <a className={'icon'}>
                  <b>
                    <FacebookSsg />
                  </b>
                </a>
                <a className={'icon'}>
                  <b>
                    <InstagramSvg />
                  </b>
                </a>
              </li>
            </ul>
          </div>
          <div className={'col'}>
            <p className={'title'}>{t('company')}</p>
            <ul>
              <li>
                <Link to={'/about-us'}>{t('about-us')}</Link>
              </li>
              <li>
                <Link to={'/contact-us'}>{t('contact-us')}</Link>
              </li>
              <li>
                <Link to={'/privacy-policy'}>{t('privacy-policy')}</Link>
              </li>
              <li>
                <Link to={'/corporate-group'}>{t('corporate-group')}</Link>
              </li>
              <li>
                <Link to={'/terms-condition'}>{t('terms-condition')}</Link>
              </li>
            </ul>
          </div>
          <div className={'col'}>
            <p className={'title'}>{t('customer-service')}</p>
            <ul>
              <li>
                <Link to={'/user-account'}>{t('my-account')}</Link>
              </li>
              <li>
                <Link to={'/how-to-pay'}>{t('how-to-pay')}</Link>
              </li>
              <li>
                <Link to={'/how-to-order'}>{t('how-to-order')}</Link>
              </li>
              <li>
                <Link to={'/how-to-return'}>{t('how-to-return')}</Link>
              </li>
              <li>
                <Link to={'/customer-feedback'}>{t('customer-feedback')}</Link>
              </li>
            </ul>
          </div>
          <div className={'col'}>
            <p className={'title'}>{t('resources')}</p>
            <ul>
              <li>
                <Link to={'/blog'}>{t('blog')}</Link>
              </li>
              <li>
                <Link to={'/news'}>{t('news')}</Link>
              </li>
              <li>
                <Link to={'/sitemap.xml'}>{t('sitemap')}</Link>
              </li>
            </ul>
          </div>
          <div className={'col'}>
            <p className={'title'}>{t('sell-your-product-on-greenia')}</p>
            <div className={'form'}>
              <input
                type={'email'}
                value={seller.email}
                onChange={(e) => checkSeller(e.target.value)}
                placeholder={'email-address'}
                aria-invalid={sellerError ? true : false}
              />
              {sellerError && <span>{sellerError}</span>}
              <button type={'button'} onClick={joinSeller}>
                {t('submit')}
              </button>
            </div>
          </div>
        </div>

        <div className={'divider'} />
        <p className={'copy-right'}>{t('copy-right')}</p>
      </S.Footer>
    </S.FooterLayout>
  );
};

export default FooterLayout;
