import { ITheme } from '../types';


export const darkColorsTheme: ITheme = {
  primary: '#00B78B',
  navTextColor:'#002042',
  borderBaseColor: '#707974',
  itemHoverBg: '#6750A41F',
  mainLinkColor: '#00B78B',
  mainLinkHoverColor: '#00B78B',
  mainLinkActiveColor: '#00B78B',
  searchIconColor: '#404944',
  spinnerBase: '#00B78B',
  deactivatedLinkColor: '#89938D',
  porpule: '#975BFA',
  lightGray: '#E7FFF2',
  yellow: '#FFD182',
  darkBlue: '#001C3B',
  gray: '#F2F4F1',
  red: '#E71212',
  redLight: '#FFDAD6',
  lighterGray: '#FDFBFF',
  lightPorpul: '#EADDFF',
  porpule2: '#7320F4',
  darkPorpul: '#24005B',
  lightGreen:'#D9F4EE',
  darkGrey:'#49454F'
};

export const antDarkColorsTheme = {
  successBg: '#e6fff2',
  successBorder: '#79fcc4',
};
