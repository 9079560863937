import React, {useEffect, useState} from 'react';
import * as S from './styles.style';
import avatar from "@app/assets/images/noUser.png"
import {deleteToken, deleteUser, readUser} from "@app/utils/local-storage.service";
import {UserDomain} from "@app/domains/user.domain";
import {ArrowRightSvg} from "@app/assets/images/svgs/arrow-right.svg"
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {DashboardSvg} from "@app/assets/images/svgs/dashboard.svg";
import {FavoriteSvg} from "@app/assets/images/svgs/favorite.svg";
import {InquirySvg} from "@app/assets/images/svgs/inquiry.svg";
import {LogoutSvg} from "@app/assets/images/svgs/logout.svg";
import {User2Svg} from "@app/assets/images/svgs/user2.svg";
import {toast} from "react-toastify";

interface Props {
    open: boolean;
}

const SidebarLayout = ({ open }: Props) => {
    const {t} = useTranslation()
    const [user, setUser] = useState<UserDomain | null>()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        setUser(readUser())
    }, [])

    const handleLogout = () =>{
        deleteUser()
        deleteToken()
        navigate('/')

        toast.success(t('logout-done'))
    }

    return (
        <S.Wrapper className={open ? 'open' : ''}>
            <div className={'user'}>
                <img src={user?.avatar || avatar}/>

                <p>{user?.firstName} {user?.lastName}</p>
                <p>{user?.email}</p>
            </div>
            <div className={'items'}>
                <div className={'top'}>
                    <ul>
                        <li className={location.pathname === '/user-account' ? 'active' : ''}>
                            <Link to={'/user-account'}>
                                <b className={'stroke'}>
                                    <DashboardSvg/>
                                </b>
                                <p>{t('dashboard')}</p>
                                <b className={'fill'}>
                                    <ArrowRightSvg/>
                                </b>
                            </Link>
                        </li>
                        <li className={(location.pathname === '/user-account/inquiries' || location.pathname.startsWith('/user-account/inquiries')) ? 'active' : ''}>
                            <Link to={'inquiries'}>
                                <b className={'fill'}>
                                    <InquirySvg/>
                                </b>
                                <p>{t('inquiries')}</p>
                                <b className={'fill'}>
                                    <ArrowRightSvg/>
                                </b>
                            </Link>
                        </li>
                        <li className={location.pathname === '/user-account/wish-list' ? 'active' : ''}>
                            <Link to={'wish-list'}>
                                <b className={'fill'}>
                                    <FavoriteSvg/>
                                </b>
                                <p>{t('wish-list')}</p>
                                <b className={'fill'}>
                                    <ArrowRightSvg/>
                                </b>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={'bottom'}>
                    <ul>
                        <li className={location.pathname === '/user-account/profile' ? 'active' : ''}>
                            <Link to={'profile'}>
                                <b className={'stroke'}>
                                    <User2Svg/>
                                </b>
                                <p>{t('update-profile')}</p>
                                <b className={'fill'}>
                                    <ArrowRightSvg/>
                                </b>
                            </Link>
                        </li>
                        <li>
                            <a onClick={handleLogout}>
                                <b className={'stroke'}>
                                    <LogoutSvg/>
                                </b>
                                <p>{t('logout')}</p>
                                <b className={'fill'}>
                                    <ArrowRightSvg/>
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </S.Wrapper>
    );
};

export default SidebarLayout;
