import React from "react";

export const EmailSvg: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.6665 4.99992C1.6665 4.55789 1.8421 4.13397 2.15466 3.82141C2.46722 3.50885 2.89114 3.33325 3.33317 3.33325H16.6665C17.1085 3.33325 17.5325 3.50885 17.845 3.82141C18.1576 4.13397 18.3332 4.55789 18.3332 4.99992V14.9999C18.3332 15.4419 18.1576 15.8659 17.845 16.1784C17.5325 16.491 17.1085 16.6666 16.6665 16.6666H3.33317C2.89114 16.6666 2.46722 16.491 2.15466 16.1784C1.8421 15.8659 1.6665 15.4419 1.6665 14.9999V4.99992ZM4.599 4.99992L9.99984 9.72575L15.4007 4.99992H4.599ZM16.6665 6.10742L10.549 11.4608C10.397 11.5939 10.2019 11.6673 9.99984 11.6673C9.7978 11.6673 9.60263 11.5939 9.45067 11.4608L3.33317 6.10742V14.9999H16.6665V6.10742Z"
                />
        </svg>

    )
}