import React from "react";

export const MenuSvg: React.FC = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.25684 10.6667V8H28.2568V10.6667H4.25684ZM4.25684 17.3333H28.2568V14.6667H4.25684V17.3333ZM4.25684 24H28.2568V21.3333H4.25684V24Z"
                  />
        </svg>
    )
}