import React from 'react';
import * as S from './styles.style';
import { TextareaAutosize } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const TextComponent: React.FC<{
    error?: string,
    value: string,
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
    placeholder?: string,
    style?: React.CSSProperties,
}> = (props) => {
    const { t } = useTranslation();

    return (
        <S.Input>
            <Box
                component="div"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100%', marginX: '0' },
                }}
            >
                <TextareaAutosize
                    value={props.value}
                    placeholder={t(props.placeholder ? props.placeholder : "")} // Changed from label to placeholder
                    defaultValue={props.value}
                    onChange={props.onChange}
                    style={{
                        ...props.style,
                        borderColor: props.error ? 'red' : undefined, // Conditional styling for error
                    }}
                />
                {/* Display error message if there is an error */}
                {props.error && (
                    <div style={{ color: 'red', marginTop: '4px' }}>
                        {props.error}
                    </div>
                )}
            </Box>
        </S.Input>
    );
};
