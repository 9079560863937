import React, { useState } from "react";
import * as S from './styles.style';
import { useTranslation } from "react-i18next";
import SignUpComponent from "@app/components/auth/sign-up.component";
import SignInComponent from "@app/components/auth/sign-in.component";

const AuthComponent: React.FC = (props) => {
    const { t } = useTranslation()
    const [selectedTab, setSelectedTab] = useState<string>('sign-up')

    const signUpTabHandler = () => setSelectedTab('sign-up')
    const signInTabHandler = () => setSelectedTab('sign-in')



    return (
        <S.LayoutMaster>
            <div className={'left'}>
                <div className={'text'}>
                    <p>{t('hello')}</p>
                    <p>{t('we-are-glad-to-see-you')}</p>
                    <p className={'small'}>{t('Please choose how you want to proceed')}</p>
                </div>
                <div className={'tabs'}>
                    <ul>
                        <li onClick={signUpTabHandler}>
                            <a className={selectedTab === 'sign-up' ? 'active' : ''}>
                                <span>{t('sign-up')}</span>
                            </a>
                        </li>
                        <li onClick={signInTabHandler}>
                            <a className={selectedTab === 'sign-in' ? 'active' : ''}>
                                <span>{t('sign-in')}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={'right'}>
                <div style={{ display: selectedTab === 'sign-up' ? 'block' : 'none' }}>
                    <SignUpComponent />
                </div>
                <div style={{ display: selectedTab === 'sign-in' ? 'block' : 'none' }}>
                    <SignInComponent />
                </div>
            </div>
        </S.LayoutMaster>
    )
}

export default AuthComponent;