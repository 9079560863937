import { QuickBuyData } from '@app/interfaces/quick-buy.interface';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputComponent } from '../input/input.component';
import { TextComponent } from '../text/text.component';
import ReCAPTCHA from 'react-google-recaptcha';
import validator from 'validator';
import { toast } from 'react-toastify';
import { QuickBuyInquiryData, SendQuickBuy } from '@app/api/inquiry.api';
import * as S from './inquiry-quick-buy.style';

interface Props {
  tableData: QuickBuyData[];
  onClose: () => void;
}

export const InquiryQuickBuyModal = ({ tableData, onClose }: Props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const handleSetEmail = (event: any) => {
    const { value } = event.target;

    setEmailError('');

    setEmail(value);

    if (!validator.isEmpty(value)) {
      if (!validator.isEmail(value)) {
        setEmailError(t('invalid-email-address'));
      }
    }
  };

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const handleSetDescription = (event: any) => {
    const { value } = event.target;
    setDescriptionError('');
    setDescription(value);
  };

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>('');
  const recaptchaRef = useRef<any>(null);

  function ReCAPTCHARecaptcha(value: string | null) {
    setRecaptchaValue(value);
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitForm = () => {
    let goOn = true;
    setEmailError('');

    if (validator.isEmpty(email)) {
      setEmailError(t('required-email-address'));
      goOn = false;
    } else if (!validator.isEmail(email)) {
      setEmailError(t('invalid-email-address'));
      goOn = false;
    }

    if (!recaptchaValue) {
      toast.error(t('select-recaptcha'));
      goOn = false;
    }

    if (goOn) {
      setIsLoading(true);

      const formData: QuickBuyInquiryData = {
        email,
        description,
        captchaToken: recaptchaValue ?? '',
        inquiries: tableData.map((item) => {
          return {
            id: Number(item.id),
            quantity: Number(item.quantity),
            title: item.title,
          };
        }),
      };

      SendQuickBuy(formData)
        .then((result) => {
          if (result.success) {
            toast.success(t('inquiry-request-done'));
            setEmail('');
            setDescription('');
            setRecaptchaValue(null);
            recaptchaRef.current?.reset();
            onClose();
          } else {
            toast.error(t('inquiry-request-failed'));
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error, 'error');
          setIsLoading(false);
        });
    }
  };
  return (
    <S.Inputs>
      <h2 className='title'>{t('request-quick-buy')}</h2>
      <div className="form-group">
        <label>{t('enter-email-address')}</label>
        <div className="form-group-inputs">
          <InputComponent
            error={emailError}
            value={email}
            onChange={handleSetEmail}
            type={'email'}
            placeholder={'enter-email'}
          />
        </div>
      </div>
      <div className="form-group">
        <label>{t('additional-description')}</label>
        <div className="form-group-inputs">
          <TextComponent value={description} onChange={handleSetDescription} />
        </div>
      </div>

      <div className={'row'}>
        {/* @ts-ignore */}
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Le8_fcoAAAAAEmIF6EO2EjKvNaAB5GstOQNnByg"
          onChange={ReCAPTCHARecaptcha}
        />
        <S.SubmitButton type="primary" htmlType="button" loading={isLoading} onClick={handleSubmitForm}>
          {t('send-inquiry')}
        </S.SubmitButton>
      </div>
    </S.Inputs>
  );
};
