import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const ScrollToTop: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top whenever the location (path) changes
    scroll.scrollToTop({
      duration: 500, // Smooth scroll duration in milliseconds
      smooth: true,  // Enable smooth scrolling
    });
  }, [location]);

  return null; // This component does not render anything
};

export default ScrollToTop;
