import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {InputComponent} from "@app/components/common/input/input.component";
import validator from "validator";
import {EyeSvg} from "@app/assets/images/svgs/eye.svg"
import {Checkbox, FormControlLabel} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import {AuthRequest, SignUp} from "@app/api/auth.api";
import {persistToken, persistUser} from "@app/utils/local-storage.service";
import {useNavigate} from "react-router-dom";
import {GoogleSvg} from "@app/assets/images/svgs/google.svg"
import {FacebookSvg} from "@app/assets/images/svgs/facebook.svg"

const SignUpComponent: React.FC = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const handleSetEmail = (target: any) => {
        setEmailError('')
        setEmail(target.target.value)
        if (!validator.isEmpty(target.target.value)) {
            if (!validator.isEmail(target.target.value)) {
                setEmailError(t('invalid-email-address'))
            }
        }
    }

    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const handleSetPassword = (target: any) => {
        setPasswordError('')
        setPassword(target.target.value)
        if (!validator.isEmpty(target.target.value)) {
            if (!validator.isStrongPassword(target.target.value)) {
                setPasswordError(t('enter-strong-password'))
            }
        }
    }

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const [rememberMe, setRememberMe] = useState<boolean>(false)
    const [agreement, setAgreement] = useState<boolean>(false)

    const [recaptchaValue, setRecaptchaValue] = useState<string | null>('')

    function ReCAPTCHARecaptcha(value: string | null) {
        setRecaptchaValue(value)
    }

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmitForm = () => {
        let goOn = true
        setEmailError('')
        setPasswordError('')

        if (validator.isEmpty(email)) {
            setEmailError(t('required-email-address'))
            goOn = false
        } else {
            if (!validator.isEmail(email)) {
                setEmailError(t('invalid-email-address'))
                goOn = false
            }
        }

        if (validator.isEmpty(password)) {
            setPasswordError(t('required-password'))
            goOn = false
        } else {
            if (!validator.isStrongPassword(password)) {
                setPasswordError(t('enter-strong-password'))
                goOn = false
            }
        }

        if (!recaptchaValue) {
            toast.error(t('select-recaptcha'))
            goOn = false
        }

        if (!agreement) {
            toast.error(t('select-sign-up-agreement'))
            goOn = false
        }

        if (goOn) {
            setIsLoading(true)

            const request: AuthRequest = {
                email: email,
                password: password,
                rememberMe: rememberMe,
                reCaptcha: recaptchaValue ?? ""
            }
            SignUp(request).then(result => {
                setIsLoading(false)
                console.log(result, "result")

                if (result.success) {
                    persistToken(result.data.token)
                    persistUser(result.data.user)

                    navigate('/user-account')
                    toast.success(t('welcome-dear-user'))
                } else {
                    if (result.data !== null) {
                        if (result.data.errors.email) {
                            setEmailError(t(result.data.errors.email))
                        }
                        if (result.data.errors.password) {
                            setPasswordError(t(result.data.errors.password))
                        }
                    } else {
                        toast.error(t(result.message))
                    }
                }
            }).catch(error=>{
                setIsLoading(false)
                toast.error(t('sign-up-failed'))
            })
        }
    }

    return (
        <S.LayoutSignUpMaster>
            <h1>{t('Check out faster and get discounts.')}</h1>


            <div className={'inputs'}>
                <InputComponent error={emailError} value={email} onChange={handleSetEmail} type={'email'}
                                placeholder={'enter-email'}/>
                <InputComponent error={passwordError} value={password} onChange={handleSetPassword} type={'password'}
                                placeholder={'password'} style={{marginTop: '10px'}}
                                svg={<EyeSvg/>} onHandleSvg={handleShowPassword} showPassword={showPassword}/>

                <FormControlLabel control={<Checkbox/>} label={t('remember-me')}
                                  onChange={() => setRememberMe(!rememberMe)} className={'remember-me'}/>

                    <FormControlLabel control={<Checkbox/>} label={t('sign-up-agreement')}
                                      onChange={() => setAgreement(!agreement)} className={'remember-me'}/>

                    {/* @ts-ignore */}
                    <ReCAPTCHA
                        sitekey="6Le8_fcoAAAAAEmIF6EO2EjKvNaAB5GstOQNnByg"
                        onChange={ReCAPTCHARecaptcha}/>

                <S.SubmitButton type="primary" htmlType="button"
                                loading={isLoading} onClick={handleSubmitForm}>
                    {t('sign-up')}
                </S.SubmitButton>
            </div>


            <div className={'other-account'}>
                <div className={'title'}>
                    <span></span>
                    <p>{t('or')}</p>
                    <span></span>
                </div>
                <div className={'buttons'}>
                    <a>
                        <b><GoogleSvg/></b>
                        <span>{t('continue-with-google')}</span>
                    </a>
                    <a>
                        <b><FacebookSvg/></b>
                        <span>{t('continue-with-facebook')}</span>
                    </a>
                </div>
            </div>
        </S.LayoutSignUpMaster>
    )
}

export default SignUpComponent;