import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';
import "./fonts.css"

export default createGlobalStyle`

  ${resetCss}
  body {
    background: var(--white);
    font-family: "robotoLight" !important;
  }

  p, span, h1, h2, h3, h4, h5, h6, a, input, li, ul, small, strong {
    font-family: "robotoLight" !important;
  }
  
  li{
    list-style: none;
  }

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  }

  [data-no-transition] * {
    transition: none !important;
  }

  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: var(--text-main-color);
  }
`;
