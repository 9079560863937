import styled from 'styled-components';
import { Layout } from 'antd';
import { media } from '@app/assets/styles/themes/constants';

export const Header = styled(Layout)`
  background: var(--white);
  width: 100%;
  z-index: 10;
  height: auto;
  position: sticky;
  top: 0;

  &.active {
    transition: top 0.4s ease-out;
    top: 0;
  }

  &.hidden {
    transition: top 0.4s ease-out;
    top: -350px;
  }

  .overlay-content {
    &.overlay {
      width: 100%;
      overflow-x: hidden;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.9);
      transition: 0.5s;
      opacity: 0.5;
    }
  }
`;

export const Navigation = styled(Layout)`
  .navigation {
    position: relative;
    z-index: 2000;
    background: var(--white);
    border-bottom: 1px solid var(--primary-color);
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 8px 70px;
    justify-content: space-between;
    box-sizing: border-box;

    @media only screen and ${media.sm} {
      padding: 8px 20px;
    }

    .logo {
      align-content: start;
      width: 180px;
      height: 74px;
    }

    .nav-links-container {
      align-items: center;
      vertical-align: center;
      align-self: center;
      align-content: end;

      @media only screen and ${media.lg} {
        display: none;
      }

      .menu-items-left {
        width: 100%;
        display: flex;

        > ul.menu {
          display: inline-flex;
          justify-content: space-between;
          margin-right: 58px;
          list-style: none;

          li.menu {
            width: auto;
            float: right;
            align-self: center;

            a {
              display: flex;
              padding: 10px;
              cursor: pointer;
              align-self: center;
              align-items: center;
              text-decoration: none;

              span {
                color: var(--nav-text-color);
                font-size: 16px;
                line-height: 24px;
                align-self: center;
                font-family: 'robotoRegular' !important;
              }

              b > svg {
                margin-top: 3px;
                stroke: var(--nav-text-color);
                align-self: center;
              }

              &:hover {
                span {
                  color: var(--main-link-hover-color);
                }

                b > svg {
                  stroke: var(--main-link-hover-color);
                }
              }

              &.active {
                span {
                  color: var(--main-link-hover-color);
                }

                b > svg {
                  stroke: var(--main-link-hover-color);
                }
              }
            }
          }
        }

        .menu-items-right {
          align-content: end;
          display: flex;
          align-items: center;
          vertical-align: center;
          align-self: center;

          div:first-child {
            display: flex;
            justify-content: space-between;
            width: auto;

            a {
              display: flex;
              text-decoration: none;
              cursor: pointer;

              img {
                width: 24px;
                height: 24px;
                margin: 5px;
                align-self: center;
              }

              span {
                text-align: center;
                color: var(--nav-text-color);
                font-size: 16px;
                line-height: 24px;
                font-family: 'robotoRegular' !important;
                vertical-align: center;
                align-self: center;
                margin-right: 10px;
              }

              &:hover {
                span {
                  color: var(--main-link-hover-color);
                }
              }
            }
          }

          div:last-child {
            display: flex;
            justify-content: space-between;
            width: auto;
            padding: 10px 15px;
            cursor: pointer;

            a {
              display: flex;
              text-decoration: none;

              span {
                text-align: center;
                color: var(--nav-text-color);
                font-size: 16px;
                line-height: 24px;
                vertical-align: center;
                font-family: 'robotoRegular' !important;
                align-self: center;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .openSub {
        z-index: 1000;
      }
    }

    .nav-mobile {
      display: none;
      align-items: center;
      vertical-align: center;
      align-self: center;
      align-content: end;
      flex-direction: row-reverse;

      b {
        cursor: pointer;
        margin-left: 10px;

        svg {
          fill: var(--black);
        }
      }
    }

    @media only screen and ${media.md} {
      .nav {
        display: none;
      }

      .logo {
        width: 120px;
      }

      .nav-mobile {
        display: flex;
      }
    }

    @media only screen and ${media.lg} {
      .nav {
        display: none;
      }

      .logo {
        width: 120px;
      }

      .nav-mobile {
        display: flex;
      }
    }

    @media only screen and ${media.xl} {

      .nav {
        width: auto;
      }
    }
  }

  .products-sub-menu {
    display: flex;
    margin: 0 auto 0 auto;
    height: auto;
    padding: 10px;
    background: var(--white);
    z-index: 1000;
    position: absolute;
    right: 5%;
    width: 40%;
    border: 1px solid var(--gray);
    border-top: 0;
    top: 94px;

    .first-col {
      width: 20%;
      height: 100%;
      margin-right: 30px;

      ul.category {
        li.category {
          a {
            display: flex;
            color: var(--black);
            cursor: pointer;
            text-decoration: none;
            margin-bottom: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            font-family: 'robotoRegular' !important;

            b > svg {
              fill: var(--black);
            }
          }

          &.active a {
            color: var(--main-link-color);

            b > svg {
              fill: var(--main-link-color);
              stroke: var(--main-link-color);
            }
          }
        }
      }
    }

    .second-col {
      width: 80%;
      height: 700px;
      margin-left: 30px;
      overflow: auto;
      border-left: 1px solid var(--gray);
      padding-left: 30px;

      ul.subCategory {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;

        li.subCategory {
          width: calc(50% - 4px);
          padding: 5px;

          a {
            display: flex;
            color: var(--black);
            cursor: pointer;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            font-family: 'robotoRegular' !important;
            text-decoration: none;
            // margin-bottom: 20px;
            
            &:hover {
              color: var(--main-link-hover-color);
            }
          }


          ul {
            display: block;

            li {
              width: 100%;

              a {
                display: flex;
                color: var(--gray);
                cursor: pointer;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-family: 'robotoRegular' !important;
                text-decoration: none;
                // margin-bottom: 20px;
              }
            }
          }
        }
      }
    }

    @media only screen and ${media.xl} {
      width: calc(100% - 40%);
    }

    @media only screen and ${media.xxl} {
      width: calc(100% - 45%);
    }
  }

  .brands-sub-menu-container {
    width: 200px;
    display: flex;
    margin: 0 auto;
    height: 300px;
    padding: 10px;
    background: var(--white);
    z-index: 1000;
    position: absolute;
    top: 94px;
    right: 450px;
    border: 1px solid var(--gray);
    border-top: 0;

    .brands-sub-menu {
      list-style: none;
      width: 100%;
      margin: 10px auto 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      li {
        // padding-left: 50px;
        a {
          display: flex;
          color: var(--black);
          cursor: pointer;
          text-decoration: none;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          font-family: 'robotoRegular' !important;

          b > svg {
            fill: var(--black);
          }
        }

        &:hover a {
          color: var(--main-link-hover-color);
        }

        &.active a {
          color: var(--main-link-color);

          b > svg {
            fill: var(--main-link-color);
          }
        }
        li:last-child {
          padding-left: 0;
        }
      }
    }

    @media only screen and ${media.xl} {
      // right: calc(100% - 40%);
    }

    @media only screen and ${media.xxl} {
      // right: 25%;
    }
  }

  .search-container {
    width: 100%;
    height: 11rem;
    background: var(--primary-color);
    padding: 16px 0;
    box-sizing: border-box;

    .search-box {
      display: flex;
      background: var(--white);
      border-radius: 5px;
      width: 80%;
      max-width: 800px;
      height: 50px;
      padding: 12px;
      margin: 0 auto;

      &.height-100 {
        height: 120px;
      }

      textarea {
        width: 100%;
        background: var(--white);
        border: none;
        outline: none;
        margin-left: 10px;
        margin-right: 10px;
        color: var(--black);
        padding-top: 10px;

        &.height-100 {
          height: 100px;
        }
      }

      input {
        width: 100%;
        background: var(--white);
        color: var(--black);
        border: none;
        outline: none;
        margin-left: 10px;
        margin-right: 10px;
      }
      input::placeholder {
        color: var(--dark-grey);
      }

      b > svg {
        fill: var(--search-icon-color);
        cursor: pointer;
      }
    }

    .search-box-footer {
      display: flex;
      width: 80%;
      max-width: 800px;
      justify-content: space-between;
      padding: 10px 22px;
      margin: 0 auto;

      > div:first-child {
        display: flex;

        label {
          color: var(--white);
          align-self: center;
          width: 100%;
          margin-left: 10px;
          font-weight: 500;
          font-size: 13px;
        }
      }

      .advance-search {
        color: var(--white);
        align-self: center;
        margin-top: 5px;
        width: 200px;
        text-align: right;
        font-size: 14px;
      }
    }

    .search-footer {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      margin-top: 28px;
      padding-right: 70px;

      .send-inquiry {
        color: var(--white);
        align-self: center;
        text-align: right;
        display: flex;
        flex-direction: row;
        margin-right: 20px;
        text-decoration: none;

        b > svg {
          fill: var(--white);
          stroke: var(--white);
          margin-right: 5px;
        }
      }

      .buying-tools {
        color: var(--white);
        align-self: center;
        text-align: right;
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        text-decoration: none;
        align-items: center;

        b > svg {
          fill: var(--white);
          stroke: var(--white);
          // margin-right: 5px;
          margin-top: 5px;
        }
      }
    }

    @media only screen and ${media.sm} {
      display: none;
    }
  }

  .search-mobile {
    width: 95%;
    height: 40px;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 0 auto;
    display: none;

    @media only screen and ${media.sm} {
      display: flex;
    }

    b > svg {
      fill: var(--nav-text-color);
      stroke: var(--nav-text-color);
    }

    .search-mobile-footer {
      display: flex;
      width: 100%;
      font-size: 12px;

      .send-inquiry {
        color: var(--nav-text-color);
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        text-decoration: none;

        b > svg {
          fill: var(--nav-text-color);
          stroke: var(--nav-text-color);
          margin-right: 5px;
          font-size: 12px;
        }
      }

      .buying-tools {
        color: var(--nav-text-color);
        align-self: center;
        text-align: left;
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        padding-left: 0;
        text-decoration: none;
        align-items: center;
        font-size: 12px;

        b > svg {
          fill: var(--nav-text-color);
          stroke: var(--nav-text-color);
          margin-right: 5px;
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
`;

export const Search = styled(Layout)`
  .search-container {
    width: 100%;
    height: auto;
    background: #F2F2F2;
    padding: 16px 0;
    box-sizing: border-box;

    .search-box {
      display: flex;
      width: 80%;
      height: auto;
      margin: 0 auto;
      gap: 10px;
      
      &.height-100 {
        height: 120px;
      }
        
      textarea {
        width: 100%;
        background: var(--white);
        border: none;
        outline: none;
        color: var(--black);
        padding-top: 10px;
        border-radius: 5px;
        padding: 12px;
          
        &.height-100 {
          height: 100px;
        }
      }
            
      input {
        width: 100%;
        background: var(--white);
        color: var(--black);
        border-radius: 5px;
        padding: 12px;
        border: none;
        outline: none;
      }
        input::placeholder { 
        color: var(--dark-grey);
      } 

      b > svg {
        fill: var(--search-icon-color);
        cursor: pointer;
      }

      button {
        background: var(--primary-color);
        color: var(--white);
        padding: 12px;
        height: 50px;
      }
    }
    
    .search-box-footer {
      display: flex;
      width: 80%;
      justify-content: space-between;
      padding: 10px 0;
      margin: 0 auto;
      
      > div:first-child {
      display: flex;


      label {
        color: var(--black);
        align-self: center;
        width: 100%;
        margin-left: 10px;
        font-weight: 500;
        font-size: 13px;
        text-wrap: nowrap;
      }

       }

      .advance-search {
        color: var(--black);
        align-self: center;
        margin-top: 5px;
        width: 200px;
        text-align: right;
        font-size: 14px;
        text-wrap: nowrap;
      }
    }
`;
