import styled from "styled-components";
import {Layout} from "antd";
import {BaseButton} from "@app/components/common/BaseButton/BaseButton";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  border-radius: 10px;
  height: 670px;
  
  .left {
    width: 384px;
    background: var(--primary-color);
    height: 100%;

    .text {
      padding-top: 50px;

      p {
        color: var(--black);
        text-align: center;
        font-family: "robotoRegular" !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      p.small {
        color: var(--black);
        text-align: center;
        font-family: "robotoRegular" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;
      }
    }

    .tabs {
      position: relative;
      right: 0;
      float: right;
      margin-top: 70px;

      ul {
        list-style-type: none;
        display: inline-block;

        li {
          margin-bottom: 30px;
          cursor: pointer;
          transition: 0.3s;

          a {
            color: var(--black);
            font-family: "robotoBlack" !important;
            font-size: 16px;
            line-height: 24px;
            padding: 20px;
            font-weight: bold;
            display: block;
          }

          > .active {
            background: var(--white);
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
          }
        }
      }
    }
  }
  .right{
    width: 100%;
    background: var(--white);
    height: 100%;
    padding: 30px;
  }
`;

export const LayoutSignUpMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  h1 {
    color: var(--black);
    text-align: center;
    font-family: "robotoMedium" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
  }

  .inputs {
    margin-top: 40px;
    padding: 30px;

    .remember-me {
      color: var(--black);
      font-family: "robotoMedium" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  .other-account {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;

    .title {
      display: flex;
      justify-content: space-between;

      span {
        background: #707974;
        height: 1px;
        width: 100%;
      }

      p {
        color: #707974;
        font-family: "robotoMedium" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: -10px;
      }
    }

    .buttons {
      display: flex;
      width: 80%;
      justify-content: space-between;
      margin: 0 auto 0 auto;

      a {
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--black);
        margin: 10px;
        width: 100%;
        cursor: pointer;
        text-align: center;
        align-items: center;
        display:flex;

        span {
          color: var(--primary-color);
          text-align: center;
          font-family: "robotoMedium" !important;
          font-style: normal;
          font-weight: bold;
          font-size:14px;
        }

        b {
          margin-right: 10px;
        }

        &:hover {
          background: var(--item-hover-bg);
        }
      }
    }
  }
`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  cursor: pointer; 
  width: 100%;
  margin: 30px auto 30px auto;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;