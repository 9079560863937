import {httpApi} from "@app/api/http.api";
import { InquiryProductDomain } from "@app/domains/inquiry-product.domain";

export interface InquiryResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        uuid: string;
    }
}

export interface InquiryDataResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        inquiry: InquiryProductDomain;
    }
}

export interface QuickBuyInquiryData {
    email: string;
    description: string;
    captchaToken: string
    inquiries: Array<{ id: number; quantity: number; title: string;}>
}

export const SendInquiryCart = (id: number, formData: FormData): Promise<InquiryDataResult> =>
    httpApi.post<InquiryDataResult>('inquiries/create/cart/' + id, formData).then(({data}) => data);

export const SendQuickBuy = (inquiry: QuickBuyInquiryData): Promise<InquiryDataResult> =>
    httpApi.post<InquiryDataResult>('inquiries/create/quick-buy', inquiry).then(({data}) => data);

export const SendInquiry = (formData: FormData): Promise<InquiryResult> =>
    httpApi.post<InquiryResult>('inquiries/create', formData).then(({data}) => data);