import axios, { AxiosRequestHeaders } from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/error.api';
import { readToken } from '@app/utils/local-storage.service';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const httpApiIp = axios.create({
  baseURL: "https://api.ipify.org/?format=json",
});

httpApi.interceptors.request.use((config) => {
  config.headers = {
    ...(config.headers), // Cast existing headers to RawAxiosHeaders
    language: 'en',
    Authorization: `Bearer ${readToken()}`,
  } as unknown as AxiosRequestHeaders;
  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  const responseData = error.response?.data as ApiErrorData | undefined;
  const message = responseData?.message || error.message;

  throw new ApiError<ApiErrorData>(message, responseData);
});

export interface ApiErrorData {
  message: string;
}
