import styled from 'styled-components';
import { Layout } from 'antd';
import { media } from '@app/assets/styles/themes/constants';

export const FooterLayout = styled(Layout)`
  width: 100%;
  height: auto;
`;

export const NeedHelp = styled(Layout)`
  position: relative;
  width: 100%;

  .help {
    width: 96%;
    margin-top: 70px;
    border-right: 10px solid var(--primary-color);
    height: 600px;

    .title {
      display: flex;
      justify-content: space-between;

      .green-line {
        background: var(--primary-color);
        width: 100%;
        height: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .radius-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      span {
        width: 20%;
        color: var(--black);
        text-align: center;
        font-family: 'robotoRegular' !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        align-self: center;
        margin-top: -10px;
      }
    }

    .body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 90%;
      margin: 20px auto 30px auto;
      gap: 24px;

      .box {
        align-items: center;
        height: 220px;
        width: 100%;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        padding: 32px;
        border-radius: 8px;

        margin-bottom: 20px;


          align-self: center;
          text-decoration: none;

          h3 {
            color: var(--black);
            font-family: 'robotoRegular' !important;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            align-items: center;
            align-self: center;
            text-decoration: none;

            b svg {
              fill: var(--black);
              align-self: center;
              margin-top: 2px;
            }
          }

          p {
            color: var(--black);
            text-align: center;
            font-family: 'robotoRegular' !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 10px;
          }
      }
    }

    @media only screen and ${media.xl} {
      .body {
        .box {
          height: 300px;
        }
      }
    }

    @media only screen and ${media.lg} {
      height: 750px;

      .body {
        flex-wrap: wrap;

        .box {
          width: 47.5%;
          height: 200px;
        }
      }
    }

    @media only screen and ${media.md} {
      height: 850px;

      .body {
        .box {
          height: 250px;
        }
      }
    }

    @media only screen and ${media.sm} {
      width: 100%;
      border: 0;
      height: auto;

      .body {
        flex-wrap: wrap;
        margin: 0 auto 0 auto;

        .box {
          margin-top: 5px;
          margin-bottom: 5px;
          width: 100%;
          height: 198px;
        }
      }
    }
  }

  .newsletter {
    margin-top: -300px;

    b svg {
      position: relative;
      margin-bottom: -6%;
      margin-left: 100px;
    }

    .background {
      background: rgba(0, 82, 123, 1);
      padding: 10px 24px;
      display: flex;
      align-items: center;

      .title {
        display: table;
        margin-left: 30%;
        padding: 20px;

        p {
          color: var(--white);
          font-family: 'robotoRegular' !important;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;
        }

        p:last-child {
          color: var(--white);
          font-family: 'robotoRegular' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-top: 10px;
        }
      }

      .email-form {
        width: 35%;
        margin-bottom: 20px;
        display: flex;
        position: relative;

        input {
        background: transparent;
        border: 1px solid var(--white);
        padding: 10px 24px;
        border-radius: 8px;
        font-family: 'robotoRegular' !important;
        color: var(--white);
        height: 50px;
        width: 60%;

        &[aria-invalid="true"] {
          border-color: red;
        }

        &[aria-invalid="true"]:focus {
          outline: 2px solid red;
        }

        &::placeholder {
          font-family: 'robotoRegular' !important;
          color: var(--white);
        }

        }
        
        span {
          position: absolute;
          left: 0;
          bottom: -25px;
          color: red;
        }

      button {
        background: var(--white);
        padding: 10px 24px;
        border-radius: 10px;
        margin-left: 30px;
        height: 50px;
        color: var(--primary-color);
        font-family: 'robotoRegular' !important;
        border: none;
        width: 30%;
        cursor: pointer;

        &:hover {
          background: var(--gray-color);
        }
      }      
      }
    }
  }

  @media only screen and ${media.xl} {
    .newsletter {
      b svg {
        margin-bottom: -150px;
        width: 350px;
      }
    }
  }

  @media only screen and ${media.lg} {
    .newsletter {
      // margin-top: -300px;

      b svg {
        // position: relative;
        margin-bottom: -200px;
        margin-left: 7%;
        width: 270px;
      }

      .background {
        flex-wrap: wrap;

        .email-form {
          margin-left: 30%;
          padding-left: 20px;
          width: calc(70% - 20px);

          span {
            left: 20px;
          }
        }
      }
  }

  @media only screen and ${media.md} {
    .newsletter {
      margin-top: 10px;

      b svg {
        display: none;
      }

      .background {
        display: block;

        .title {
          margin-left: 0;
        }

        .email-form {
          margin-left: 0;
          margin-bottom: 20px;
          width: 100%;
        }

        input {
          width: 60%;
        }

        button {
          width: 35%;
          margin-left: 0;
        }
      }
    }
  }
`;

export const Footer = styled(Layout)`
  width: 100%;
  height: auto;
  background: var(--dark-blue-color);
  padding-bottom: 20px;

  .logo-container {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 86%;
    .logo {
      width: 180px;
      height: 74px;
    }

    @media only screen and ${media.lg} {
      display: flex;
      justify-content: center;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    width: 86%;
    margin: 0 auto 0 auto;

    .col {
      p {
        color: var(--white);
        font-family: 'robotoMedium' !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 30px;
      }

      ul {
        list-style-type: none;

        li {
          margin-top: 8px;
          margin-bottom: 8px;

          a {
            color: var(--white);
            font-family: 'robotoRegular' !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration: none;
            cursor: pointer;

            b svg {
              fill: var(--white);
              margin-right: 10px;
              margin-top: 10px;
              align-self: center;
            }
          }

          &.social {
            display: flex;

            P {
              color: var(--white);
              font-family: 'robotoRegular' !important;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              margin-top: 10px;
            }

            .icon {
              margin-left: 10px;
            }
          }
        }
      }

      .form {
        display: table;
        position: relative;

        input {
          background: transparent;
          border: 1px solid var(--white);
          padding: 10px 24px;
          border-radius: 8px;
          font-family: 'robotoRegular' !important;
          color: var(--white);
          height: 50px;
          width: 100%;
          margin-bottom: 15px;

          &[aria-invalid="true"] {
            border-color: red;
          }

          &[aria-invalid="true"]:focus {
            outline: 2px solid red;
          }

          &::placeholder {
            font-family: 'robotoRegular' !important;
            color: var(--white);
          }
        }

        span {
          position: absolute;
          left: 0;
          top: 55px;
          color: red;
        }

        button {
          background: var(--white);
          padding: 10px 24px;
          border-radius: 10px;
          margin-top: 30px;
          height: 50px;
          color: var(--primary-color);
          font-family: 'robotoRegular' !important;
          border: none;
          width: 100%;
          cursor: pointer;

          &:hover {
            background: var(--gray-color);
          }
        }
      }
    }

    @media only screen and ${media.lg} {
      flex-wrap: wrap;

      .col:first-of-type {
        width: 100%;

        ul {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    @media only screen and ${media.md} {
      .col:first-of-type {
        width: 100%;
        position: relative;
        margin-bottom: 30px;

        ul {
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        li:last-of-type {
          position: absolute;
          right: 0;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
          }
        }
      }

      .col {
        width: 50%;
      }

      .col:nth-of-type(3) {
        .title {
          text-wrap: nowrap;
        }
        @media only screen and (min-width: 450px) {
          padding-left: 10%;
        }
        @media only screen and (min-width: 550px) {
          padding-left: 15%;
        }
        @media only screen and (min-width: 650px) {
          padding-left: 20%;
        }
      }

      .col:last-of-type {
        width: 100%;

        .form {
          display: flex;
          gap: 20px;

          input {
            width: 60%;
          }

          button {
            margin: 0;
            width: 40%;
          }
        }
      }
    }
  }

  .divider {
    margin-top: 20px;
    margin-bottom: 20px;
    background: var(--white);
    width: 100%;
    height: 1px;
  }

  .copy-right {
    color: var(--white);
    text-align: center;
    font-family: 'robotoRegular' !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  @media only screen and ${media.sm} {
    .row {
      flex-wrap: wrap;

      .col:first-child {
        width: 100%;
      }

      .col {
        p {
          margin-top: 30px;
        }
      }
    }
  }
`;
