import React from "react";

export const DashboardSvg: React.FC = () => {
    return (
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                  d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"/>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                  d="M9.75 8.75V19"/>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5 8.25H19"/>
        </svg>

    )
}