import React from "react";

export const EyeSvg : React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9166 9.99967C12.9166 10.3827 12.8412 10.762 12.6946 11.1158C12.5481 11.4697 12.3332 11.7912 12.0624 12.0621C11.7915 12.3329 11.47 12.5477 11.1161 12.6943C10.7623 12.8409 10.383 12.9163 9.99998 12.9163C9.61696 12.9163 9.23769 12.8409 8.88382 12.6943C8.52995 12.5477 8.20842 12.3329 7.93759 12.0621C7.66675 11.7912 7.45191 11.4697 7.30533 11.1158C7.15875 10.762 7.08331 10.3827 7.08331 9.99967C7.08331 9.22613 7.3906 8.48426 7.93759 7.93728C8.48457 7.3903 9.22643 7.08301 9.99998 7.08301C10.7735 7.08301 11.5154 7.3903 12.0624 7.93728C12.6094 8.48426 12.9166 9.22613 12.9166 9.99967Z"
               />
            <path
                d="M10 2.91699C12.86 2.91699 15.1033 4.19533 16.7117 5.61783C18.3167 7.03699 19.3217 8.62616 19.725 9.33449C19.8417 9.53699 19.9032 9.7666 19.9032 10.0003C19.9032 10.234 19.8417 10.4637 19.725 10.6662C19.3217 11.3745 18.3167 12.9637 16.7117 14.3837C15.1042 15.8053 12.86 17.0837 10 17.0837C7.14001 17.0837 4.89668 15.8053 3.28835 14.3837C1.68335 12.9653 0.678348 11.3753 0.275015 10.667C0.1583 10.4645 0.0968628 10.2349 0.0968628 10.0012C0.0968628 9.76744 0.1583 9.53782 0.275015 9.33533C0.678348 8.62699 1.68335 7.03783 3.28835 5.61699C4.89585 4.19533 7.14001 2.91699 10 2.91699ZM1.36085 9.95449C1.35232 9.96832 1.34744 9.98409 1.34668 10.0003C1.34751 10.017 1.35168 10.0328 1.36085 10.047C1.72835 10.692 2.65335 12.1528 4.11668 13.447C5.57585 14.7378 7.53751 15.8337 10 15.8337C12.4625 15.8337 14.425 14.7378 15.8833 13.447C17.3467 12.1528 18.2717 10.692 18.6392 10.047C18.648 10.033 18.6529 10.0169 18.6533 10.0003C18.6529 9.98378 18.648 9.96767 18.6392 9.95366C18.2717 9.30949 17.3467 7.84783 15.8833 6.55366C14.4242 5.26283 12.4625 4.16699 10 4.16699C7.53751 4.16699 5.57501 5.26199 4.11668 6.55449C2.65335 7.84783 1.72835 9.30949 1.36085 9.95449Z"
                />
        </svg>
    )
}