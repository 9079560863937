import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import RequireAuth from '@app/router/RequireAuth';
import { Loading } from '@app/components/common/loading.component';
import HomeLayout from '@app/components/layouts/home/home.layout';
import MainLayout from '@app/router/layout';
import UserAccountLayout from '@app/components/layouts/user-account/user-account.layout';
import QuickBuyPage from '@app/pages/quick-buy/quick-buy';

// Lazy-loaded components
const HomePage = lazy(() => import('@app/pages/home/index.page'));
const SingleProductPage = lazy(() => import('@app/pages/products/single/index.page'));
const CartPage = lazy(() => import('@app/pages/cart/cart.page'));
const RequestOrderPage = lazy(() => import('@app/pages/cart/request-order/index.page'));
const RequestOrderDetailPage = lazy(() => import('@app/pages/cart/request-order/detail.page'));
const UserAccountPage = lazy(() => import('@app/pages/user-account/user-account.page'));
const UserAccountInquiryPage = lazy(() => import('@app/pages/user-account/inquiries/inquiries.page'));
const UserAccountInquiryDetailPage = lazy(() => import('@app/pages/user-account/inquiries/inquiry-detail.page'));
const WishListPage = lazy(() => import('@app/pages/user-account/wish-list/wish-list.page'));
const ProfilePage = lazy(() => import('@app/pages/user-account/profile/profile.page'));
const LoginPage = lazy(() => import('@app/pages/auth/login.page'));
const OtherPage = lazy(() => import('@app/pages/page/page.page'));
const AboutUsPage = lazy(() => import('@app/pages/page/about-us.page'));
const ContactUsPage = lazy(() => import('@app/pages/page/contact-us.page'));
const ProductCategoryPage = lazy(() => import('@app/pages/products/product-category/product-category.page'));
const BrandPage = lazy(() => import('@app/pages/brand/brand.page'));
const AdvanceSearchPage = lazy(() => import('@app/pages/advance-search/advance-search.page'));
const HotProductsPage = lazy(() => import('@app/pages/products/hot-products/hot-products.page'));
const NewProductsPage = lazy(() => import('@app/pages/products/new-products/new-products.page'));
const SearchProductsPage = lazy(() => import('@app/pages/products/search-products/search-products.page'));
const SendInquiryPage = lazy(() => import('@app/pages/send-inquiry/send-inqury.page'));
const NotFoundPage = lazy(() => import('@app/pages/not-found/not-found'));

const SitemapRoute = () => {
  useEffect(() => {
    window.location.href = "/sitemap.xml"; // Redirect to the static file

  }, []);

  return null; // Render nothing since the file is being served directly
};

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Public routes */}
          <Route
            path="/"
            element={
              <MainLayout>
                <HomeLayout />
              </MainLayout>
            }
          >
            <Route index element={<HomePage />} />

            {/* Smart Products */}
            <Route path="products">
              <Route path=":slug" element={<SingleProductPage />} />
              <Route path="search" element={<SearchProductsPage />} />
            </Route>
            <Route path="product-category/:slug" element={<ProductCategoryPage />} />
            <Route path="brands/:slug" element={<BrandPage />} />
            <Route path="advance-search" element={<AdvanceSearchPage />} />
            <Route path="hot-products" element={<HotProductsPage />} />
            <Route path="new-products" element={<NewProductsPage />} />

            {/* Cart */}
            <Route path="cart">
              <Route index element={<CartPage />} />
              <Route path="request-order">
                <Route index element={<RequestOrderPage />} />
                <Route path="detail/:uuid" element={<RequestOrderDetailPage />} />
              </Route>
            </Route>

            {/* Auth */}
            <Route path="auth/login" element={<LoginPage />} />

            {/* Static pages */}
            <Route path="about-us" element={<AboutUsPage />} />
            <Route path="contact-us" element={<ContactUsPage />} />
            <Route path="send-inquiry" element={<SendInquiryPage />} />
            <Route path="quick-buy" element={<QuickBuyPage />} />
            <Route path="sitemap.xml" element={<SitemapRoute />} />
            <Route path=":slug" element={<OtherPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          {/* Protected routes */}
          <Route
            path="/user-account"
            element={
              <RequireAuth>
                <UserAccountLayout />
              </RequireAuth>
            }
          >
            <Route index element={<UserAccountPage />} />

            <Route path="inquiries">
              <Route index element={<UserAccountInquiryPage />} />
              <Route path=":uuid" element={<UserAccountInquiryDetailPage />} />
            </Route>
            <Route path="wish-list" element={<WishListPage />} />
            <Route path="profile" element={<ProfilePage />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
