import styled from 'styled-components';
import { Layout } from 'antd';
import { media } from '@app/assets/styles/themes/constants';

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  overflow-x: hidden;

  textarea {
    background: transparent;
  }
`;

export const LayoutMain = styled(Layout)`

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;

export const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1.5rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1100;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;