import React from "react";

export const GoogleSvg: React.FC = () =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1712 8.36824H17.5V8.33366H9.99996V11.667H14.7095C14.0225 13.6074 12.1762 15.0003 9.99996 15.0003C7.23871 15.0003 4.99996 12.7616 4.99996 10.0003C4.99996 7.23908 7.23871 5.00033 9.99996 5.00033C11.2745 5.00033 12.4341 5.48116 13.317 6.26658L15.6741 3.90949C14.1858 2.52241 12.195 1.66699 9.99996 1.66699C5.39788 1.66699 1.66663 5.39824 1.66663 10.0003C1.66663 14.6024 5.39788 18.3337 9.99996 18.3337C14.602 18.3337 18.3333 14.6024 18.3333 10.0003C18.3333 9.44158 18.2758 8.89616 18.1712 8.36824Z" fill="#FFC107"/>
            <path d="M2.62744 6.12158L5.36536 8.12949C6.10619 6.29533 7.90036 5.00033 9.99994 5.00033C11.2745 5.00033 12.4341 5.48116 13.317 6.26658L15.6741 3.90949C14.1858 2.52241 12.1949 1.66699 9.99994 1.66699C6.79911 1.66699 4.02327 3.47408 2.62744 6.12158Z" fill="#FF3D00"/>
            <path d="M10 18.3336C12.1525 18.3336 14.1084 17.5099 15.5871 16.1703L13.008 13.9878C12.1432 14.6454 11.0865 15.0011 10 15.0003C7.83255 15.0003 5.99213 13.6182 5.2988 11.6895L2.5813 13.7832C3.96047 16.482 6.7613 18.3336 10 18.3336Z" fill="#4CAF50"/>
            <path d="M18.1712 8.36759H17.5V8.33301H10V11.6663H14.7096C14.3809 12.5898 13.7889 13.3968 13.0067 13.9876L13.0079 13.9868L15.5871 16.1693C15.4046 16.3351 18.3333 14.1663 18.3333 9.99967C18.3333 9.44092 18.2758 8.89551 18.1712 8.36759Z" fill="#1976D2"/>
        </svg>
    )
}