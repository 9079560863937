import React, { useRef } from 'react';
import styled from 'styled-components';
import { GlobalSpinner } from '@app/components/common/global-spinner.component';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/assets/styles/themes/themeVariables';
import createRoot from 'react-shadow';
import { StyleSheetManager } from 'styled-components';

interface LoadingProps {
  size?: string;
  color?: string;
}

export const Loading: React.FC<LoadingProps> = ({ size, color }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const spinnerColor = color || themeObject[theme].spinnerBase;

  const shadowRootRef = useRef<HTMLDivElement | null>(null);

  return (
    <createRoot.div ref={shadowRootRef} style={{ height: '100%' }}>
      <style>{`@import url('https://cdnjs.cloudflare.com/ajax/libs/antd/4.24.16/antd.min.css');`}</style>
      {shadowRootRef.current?.shadowRoot && (
        <StyleSheetManager target={shadowRootRef.current.shadowRoot}>
          <SpinnerContainer>
            <GlobalSpinner size={size} color={spinnerColor} />
          </SpinnerContainer>
        </StyleSheetManager>
      )}
    </createRoot.div>
  );
};

const SpinnerContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
`;
