import {httpApi} from "@app/api/http.api";
import {UserDomain} from "@app/domains/user.domain";

export interface AuthResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        user: UserDomain,
        token: string,
        errors: { email: string, password: string }
    }
}

export interface AuthRequest {
    email: string,
    password: string,
    rememberMe: boolean,
    reCaptcha: string
}

export const SignUp = (request: AuthRequest): Promise<AuthResult> =>
    httpApi.post<AuthResult>('auth/sign-up', request).then(({data}) => data);

export const SignIn = (request: AuthRequest): Promise<AuthResult> =>
    httpApi.post<AuthResult>('auth/sign-in', request).then(({data}) => data);
