import RoutePathComponent from '@app/components/common/route-path.component';
import { PathInterface } from '@app/interfaces/interfaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './quick-buy.style';
import createRoot from 'react-shadow';
import { StyleSheetManager } from 'styled-components';
import { Input, Table, Button } from 'antd';
import { QuickBuyData } from '@app/interfaces/quick-buy.interface';
import { DeleteSvg } from '@app/assets/images/svgs/delete.svg';
import { ProductDomain } from '@app/domains/product.domain';
import { QuickBuySearchProduct } from '@app/api/product.api';
import { getCurrencyPrice } from '@app/utils/utils';
import { Box, Modal as MuiModal, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { PlusSvg } from '@app/assets/images/svgs/plus.svg';
import { AddToCart, AddToCartRequest, CartRequest, GetCart } from '@app/api/cart.api';
import { setCart } from '@app/store/slices/cart.slice';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { ProductsStatusEnum } from '@app/constants/enums/status.enum';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { InquiryQuickBuyModal } from '@app/components/common/inquiry-quick-buy/inquiry-quick-buy.component';

const customStyles = {
  content: {
    top: '400px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth >= 768 ? '768px' : '100%',
    maxHeight: '800px',
    borderRadius: '10px',
    zIndex: '1000',
    border: 'none',
    padding: '0px',
    marginTop: '60px',
    OverflowY: 'auto',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    backdropFilter: 'blur(8px)',
  },
};

const initial = [
  {
    search: '',
    quantity: '1',
    id: '',
    title: '',
    brand: '',
    stockStatus: '',
    price: '',
    totalPrice: '',
    action: '',
    exists: false,
  } as QuickBuyData,
];

const QuickBuyPage = () => {
  const { t } = useTranslation();

  const routePaths: PathInterface[] = [
    { path: '/', title: t('home') },
    { path: '/quick-buy', title: t('quick-buy') },
  ];

  const shadowRootRef = useRef<HTMLDivElement | null>(null);

  const [tableData, setTableData] = useState<QuickBuyData[]>(initial);

  const [pendingSearch, setPendingSearch] = useState<{ value: string; index: number | null }>({
    value: '',
    index: null,
  });

  const [debouncedSearch, setDebouncedSearch] = useState<{ value: string; index: number | null }>({
    value: '',
    index: null,
  });

  const navigator = useNavigate();

  // Immediate input update
  const handleSearchChange = (value: string, index: number) => {
    setPendingSearch({ value, index });
  };

  // Debounce effect to set `debouncedSearch` after 2 seconds
  useEffect(() => {
    let timeout: any;
    if (pendingSearch.index !== null) {
      setTableData((prevData) =>
        prevData.map((row, rowIndex) =>
          rowIndex === pendingSearch.index
            ? {
                ...row,
                search: pendingSearch.value,
              }
            : row,
        ),
      );
      timeout = setTimeout(() => {
        setDebouncedSearch(pendingSearch);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [pendingSearch]);

  // Fetch data when `debouncedSearch` changes
  useEffect(() => {
    if (debouncedSearch.value && debouncedSearch.index !== null) {
      const fetchProduct = async () => {
        const productDetails: ProductDomain = (await QuickBuySearchProduct(debouncedSearch.value)).data;

        if (productDetails) {
          setTableData((prevData) =>
            prevData.map((row, rowIndex) =>
              rowIndex === debouncedSearch.index
                ? {
                    ...row,
                    search: debouncedSearch.value,
                    id: String(productDetails.regularPrice.id),
                    title: productDetails.title,
                    brand: productDetails.brand.title,
                    stockStatus:
                      (productDetails.regularPrice.stockStatus as unknown as
                        | ProductsStatusEnum.ACTIVE
                        | ProductsStatusEnum.DECLINE
                        | ProductsStatusEnum.PENDING) || '',
                    price: getCurrencyPrice(
                      productDetails.regularPrice.price,
                      productDetails.regularPrice.country.currency,
                    ) as string,
                    totalPrice: getCurrencyPrice(
                      productDetails.regularPrice.price * parseFloat(row.quantity || '1'),
                      productDetails.regularPrice.country.currency,
                    ) as string,
                    exists: true,
                  }
                : row,
            ),
          );
        } else {
          setTableData((prevData) =>
            prevData.map((row, rowIndex) =>
              rowIndex === debouncedSearch.index
                ? {
                    ...row,
                    id: '',
                    title: row.search, // Title is left empty for the input box
                    brand: '',
                    stockStatus: '',
                    price: '',
                    totalPrice: '',
                    exists: false,
                  }
                : row,
            ),
          );
        }
      };

      fetchProduct();
    }
    if (!debouncedSearch.value && debouncedSearch.index !== null) {
      setTableData((prevData) =>
        prevData.map((row, rowIndex) =>
          rowIndex === debouncedSearch.index
            ? {
                ...row,
                id: '',
                title: row.search, // Title is left empty for the input box
                brand: '',
                stockStatus: '',
                price: '',
                totalPrice: '',
                exists: false,
              }
            : row,
        ),
      );
    }
  }, [debouncedSearch, t]);

  // Handle quantity input changes
  const handleQuantityChange = (value: string, index: number) => {
    setTableData((prevData) =>
      prevData.map((row, rowIndex) => {
        if (rowIndex === index) {
          // Extract numeric price (removing the currency sign)
          const numericPrice = parseFloat(row.price.replace(/[^0-9.]/g, '') || '0');
          return {
            ...row,
            quantity: Number(value) > 0 ? value : '1',
            totalPrice: (row.price.split(' ')[0] || '') + ' ' + String(numericPrice * parseFloat(value || '0')),
          };
        }
        return row;
      }),
    );
  };

  // Add new row to the table
  const addRow = () => {
    setTableData((prevData) => [
      ...prevData,
      {
        search: '',
        quantity: '1',
        id: '',
        title: '',
        brand: '',
        stockStatus: '',
        price: '',
        totalPrice: '',
        action: '',
        exists: false,
      },
    ]);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<number | null>(null);

  // Modal show handler
  const showModal = (index: number) => {
    setRowToDelete(index);
    setIsModalVisible(true);
  };

  // Modal confirmation handler
  const handleConfirmDelete = () => {
    if (rowToDelete !== null) {
      setTableData((prevData) => prevData.filter((_, rowIndex) => rowIndex !== rowToDelete));
      toast.success(t('row-deleted'));
      setRowToDelete(null);
      setIsModalVisible(false);
    }
  };

  // Modal cancel handler
  const handleCancelDelete = () => {
    setRowToDelete(null);
    setIsModalVisible(false);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);

  const addAllToCart = () => {
    if (tableData) {
      let goOn = true;
      tableData.map((item) => {
        if (!item.id || !item.exists) goOn = false;
      });

      if (goOn) {
        tableData.forEach((item) => {
          handleAddToCard(item);
        });
        navigator('/cart');
      } else {
        setOpenModal(true);
      }
    }
  };

  const ipAddress = useAppSelector((state) => state.ip);

  const handleAddToCard = (item: QuickBuyData) => {
    const requestCart: CartRequest = {
      userId: 0,
      discountId: 0,
      ipAddress: ipAddress.ip,
    };

    GetCart(requestCart).then((result) => {
      setCart(result.data);

      const request: AddToCartRequest = {
        priceId: Number(item.id) ?? 0,
        quantity: Number(item.quantity) ?? 1,
        unitPrice: Number(item.price.replace(/[^0-9.]/g, '') || '0') ?? 0,
        cartId: result.data ? result.data.id : 0,
        userId: 0,
        ipAddress: ipAddress.ip,
        status: item.stockStatus ?? '',
      };

      AddToCart(request).then((result) => {
        if (result.success) {
          toast.success(t('add-to-cart-done'));
        } else {
          toast.error(t(result.message));
        }
      });
    });
  };

  // Table column definitions
  const columns = [
    {
      title: t('quick-buy-search'),
      dataIndex: 'search',
      width: '10%',
      render: (text: string, record: QuickBuyData, index: number) => (
        <Input value={record.search} onChange={(e) => handleSearchChange(e.target.value, index)} />
      ),
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      width: '5%',
      render: (text: string, record: QuickBuyData, index: number) => (
        <Input value={record.quantity} type='number' onChange={(e) => handleQuantityChange(e.target.value, index)} />
      ),
    },
    {
      title: t('index'),
      dataIndex: 'id',
    },
    {
      title: t('title'),
      dataIndex: 'title',
      width: '20%',
      render: (text: string, record: QuickBuyData, index: number) =>
        !record.exists && record.title === '' ? (
          text
        ) : !record.exists ? (
          <Input
            placeholder={t('title')}
            value={record.title}
            onChange={(e) => {
              const value = e.target.value;
              setTableData((prevData) =>
                prevData.map((row, rowIndex) => (rowIndex === index ? { ...row, title: value } : row)),
              );
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: t('brand'),
      dataIndex: 'brand',
    },
    {
      title: t('stock-status'),
      dataIndex: 'stockStatus',
    },
    {
      title: t('price'),
      dataIndex: 'price',
    },
    {
      title: t('total-price'),
      dataIndex: 'totalPrice',
    },
    {
      title: t('action'),
      dataIndex: 'action',
      width: '5%',
      render: (_: any, __: QuickBuyData, index: number) => (
        <b onClick={() => showModal(index)} style={{ cursor: 'pointer' }}>
          <DeleteSvg />
        </b>
      ),
    },
  ];

  return (
    <S.LayoutPage>
      <div className="box-route">
        <RoutePathComponent paths={routePaths} />
      </div>

      <div className="header">
        <h2>{t('quick-buy')}</h2>
        <p>{t('quick-buy-description')}</p>
      </div>

      <div className="box" style={{ padding: '30px' }}>
        <div className="content">
          <div className="flex-end">
            <S.SubmitButton
              type="primary"
              onClick={addAllToCart}
              style={{ marginBottom: '16px' }}
              disabled={tableData[0].title ? false : true}
            >
              {t('add-all-to-cart')}
            </S.SubmitButton>
          </div>
          <createRoot.div ref={shadowRootRef} style={{ height: '100%' }}>
            <style>{`@import url('https://cdnjs.cloudflare.com/ajax/libs/antd/4.24.16/antd.min.css');`}</style>
            {shadowRootRef.current?.shadowRoot && (
              <StyleSheetManager target={shadowRootRef.current.shadowRoot}>
                <S.StyledTableWrapper>
                  <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    rowKey={(record, index) => `${index}`}
                  />
                </S.StyledTableWrapper>
              </StyleSheetManager>
            )}
          </createRoot.div>
          <div className="flex-justify">
            <S.AddRowButton type="primary" onClick={addRow}>
              <PlusSvg /> {t('add-row')}
            </S.AddRowButton>
            <S.SubmitButton
              type="primary"
              onClick={addAllToCart}
              style={{ marginBottom: '16px' }}
              disabled={tableData[0].title ? false : true}
            >
              {t('add-all-to-cart')}
            </S.SubmitButton>
          </div>
        </div>
      </div>
      <MuiModal
        open={isModalVisible}
        onClose={handleCancelDelete}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <S.MuiModal>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              {t('confirm.delete-row')}
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {t('confirm.delete-row-content')}
            </Typography>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button onClick={handleCancelDelete}>{t('cancel')}</Button>
              <Button type="primary" onClick={handleConfirmDelete}>
                {t('confirm.confirm')}
              </Button>
            </Box>
          </Box>
        </S.MuiModal>
      </MuiModal>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(!openModal)}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={true}
      >
        <InquiryQuickBuyModal
          tableData={tableData}
          onClose={() => {
            setOpenModal(false);
            setTableData(initial);
          }}
        />
      </Modal>
    </S.LayoutPage>
  );
};

export default QuickBuyPage;
