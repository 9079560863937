import {httpApi} from "@app/api/http.api";
import {CategoryDomain} from "@app/domains/category.domain";

export interface CategoryResult {
    success: boolean,
    message: string,
    code: number,
    data: Array<CategoryDomain>
}


export const GetCategories = (): Promise<CategoryResult> =>
    httpApi.get<CategoryResult>('home/categories/').then(({data}) => data);