import React from "react";

export const ArrowSVG: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3334 6.66675L8.00002 10.0001L4.66669 6.66675"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}