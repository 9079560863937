import styled from 'styled-components';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { media } from '@app/assets/styles/themes/constants';

export const Inputs = styled.div`
  color: var(--black);
  font-family: "robotoRegular" !important;
  padding: 50px;

  .title {
    margin-bottom: 20px;
  }

  .row {
      display: flex;
      justify-content: space-between;
      width: 100%
      margin: 10px;
      margin-top: 40px;

      @media only screen and ${media.lg} {
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
      }
  }

  .form-group {
    display: flex;
    align-items: flex-start;

    @media only screen and ${media.lg} {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;

      label,
      .form-group-inputs {
        width: 100% !important;
      }
    }

    label {
      width: 30%;
    }

    .form-group-inputs {
      width: 70%;
      label {
        text-wrap: nowrap;
        @media only screen and (max-width: 437px) {
          text-wrap: wrap;
        }
      }
    }
  }
`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  cursor: pointer;
  width: 200px;

  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: 'robotoMedium' !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;
export const CancelButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--white);
  padding: 15px;
  border: none;
  cursor: pointer;
  width: 200px;

  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: 'robotoMedium' !important;
    color: var(--black);
  }
`;
