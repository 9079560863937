import React, {useState} from "react";
import * as S from './styles.style';
import {useTranslation} from "react-i18next";
import SignUpComponent from "@app/components/auth/mobile/sign-up.component";
import SignInComponent from "@app/components/auth/mobile/sign-in.component";

const AuthComponent: React.FC = (props) => {
    const {t} = useTranslation()
    const [selectedTab, setSelectedTab] = useState<string>('sign-up')

    return (
        <S.LayoutMaster>
            <div className={'tabs'}>
                <ul>
                    <li onClick={() => setSelectedTab('sign-up')}>
                        <a className={selectedTab === 'sign-up' ? 'active' : ''}>
                            <span>{t('sign-up')}</span>
                        </a>
                    </li>
                    <li onClick={() => setSelectedTab('sign-in')}>
                        <a className={selectedTab === 'sign-in' ? 'active' : ''}>
                            <span>{t('sign-in')}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div style={{display: selectedTab === 'sign-up' ? 'block' : 'none'}}>
                <SignUpComponent/>
            </div>
            <div style={{display: selectedTab === 'sign-in' ? 'block' : 'none'}}>
                <SignInComponent/>
            </div>
        </S.LayoutMaster>
    )
}

export default AuthComponent;